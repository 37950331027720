<template>
	<div class="p-index">
		<div class="head">
			<div class="inner">
				<div class="left">
					<img height="40" src="/image/logo.png"/>
					<span class="title">{{$route.name}}</span>
				</div>
				<div class="right">
					<a :href="$env.login" v-if="!/^\/login/gm.test($route.path)">
						<span>返回登录</span>
          	<i class="ss-passport icon-arrow-right3"/>
					</a>
				</div>
			</div>
		</div>
		<router-view class="content" :key="$route.path"/>
		<div class="foot">
			<div class="inner">
				<div class="top">
					<span class="hotline">咨询热线：400-995-9495</span>
					<a :href="`${$env.origin}/home`" target="_blank">首页</a>
          <i class="ss-passport icon-splitter"/>
					<a :href="`${$env.origin}/product`" target="_blank">产品介绍</a>
          <i class="ss-passport icon-splitter"/>
					<a :href="`${$env.origin}/solution`" target="_blank">解决方案</a>
          <i class="ss-passport icon-splitter"/>
					<a :href="`${$env.origin}/contact`" target="_blank">联系我们</a>
				</div>
				<div class="bottom">
					<span class="solution">解决方案</span>
					<a :href="`${$env.origin}/solution/weight`" target="_blank">一站式体重管理解决方案</a>
          <i class="ss-passport icon-splitter"/>
					<a :href="`${$env.origin}/solution/community`" target="_blank">社区营养与健康管理解决方案</a>
					<span class="copyright">
            <span>Copyright © 2016-{{new Date().getFullYear()}} 南京善识健康科技有限公司 版权所有</span>
            <a href="http://www.beian.miit.gov.cn" target="_blank">苏ICP备14048097号-5</a>
          </span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less">
.p-index {
  height: 100%;
  display: flex;
  flex-direction: column;
  a {
    &:hover {
      color: #41ac76;
    }
  }
  .icon-splitter {
    font-size: 14px;
    color: #000000;
  }
  > .head {
    background-color: #ffffff;
    > .inner {
      width: 1100px;
      height: 80px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      > .left {
        display: flex;
        align-items: center;
        > .title {
          font-size: 24px;
          margin-left: 20px;
        }
      }
      > .right {
        > a {
          &:not(:hover) {
            color: #999999;
          }
          > i {
            font-size: 14px;
          }
        }
      }
    }
  }
  > .foot {
    font-size: 12px;
    color: #666666;
    line-height: 36px;
    padding: 50px 0px 0px 0px;
    background-color: #ffffff;
    > .inner {
      width: 1100px;
      margin: 0 auto;
      > .top {
        border-bottom: 1px solid #cccccc;
        > .hotline {
          margin-right: 30px;
        }
      }
      > .bottom {
        > .solution {
          margin: 0 30px 0 205px;
        }
        > .copyright {
          color: #999999;
          margin-left: 30px;
        }
      }
    }
  }
}
</style>